body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body > div {
  margin: 20px;
}

#game-settings {
  display: flex;
  flex-direction: row;
  border: 1px #ccc solid;
}

.game-setting {
  margin: 20px;
}

#controls {
  display: flex;
  flex-direction: row;
}

#controls > button {
  margin: 10px;
}

.tenuki-board {
  width: 70vw;
  max-width: 70vh;
  height: 70vw;
  max-height: 70vh;
}
